import React, { createContext, useState } from 'react';

export const Context = createContext();

export const ContextProvider = ({ children }) => {
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <Context.Provider
      value={{
        popupOpen,
        setPopupOpen,
      }}
    >
      {children}
    </Context.Provider>
  );
};
