const React = require('react');
const isWebpSupported = require('supports-webp');
const { ContextProvider } = require('./src/context');

exports.onClientEntry = () => {
  const htmlEl = document.documentElement;

  isWebpSupported.then((supported) => {
    if (supported) {
      htmlEl.classList.add('webp');
    } else {
      htmlEl.classList.add('no-webp');
    }
  });

  const windowPathname = window.location.pathname;

  // TODO: survey display details
  if (
    typeof window !== 'undefined' &&
    windowPathname !== '/share/screver-promo' &&
    windowPathname !== '/events/screver-ocx-program/' &&
    windowPathname !== '/events/screver-ocx-program' &&
    windowPathname !== '/events/screver-ocx-program/program' &&
    windowPathname !== '/events/screver-ocx-program/program/' &&
    windowPathname !== '/events/screver-ocx-program/participants' &&
    windowPathname !== '/events/screver-ocx-program/participants/' &&
    windowPathname !== '/events/screver-ocx-program/feedback' &&
    windowPathname !== '/events/screver-ocx-program/feedback/' &&
    windowPathname !== '/events/screver-ocx-program/casino-instructions' &&
    windowPathname !== '/events/screver-ocx-program/casino-instructions/' &&
    windowPathname !== '/events/screver-ocx' &&
    windowPathname !== '/events/screver-ocx/' &&
    windowPathname !== '/events/screver-ocx/media' &&
    windowPathname !== '/events/screver-ocx/media/' &&
    windowPathname !== '/events/screver-ocx/gallery' &&
    windowPathname !== '/events/screver-ocx/gallery/' &&
    windowPathname !== '/dev/stadtcasino-baden-slider' &&
    windowPathname !== '/dev/stadtcasino-baden-slider/' &&
    windowPathname !== '/dev/screver-leverage-experts-slider' &&
    windowPathname !== '/dev/screver-leverage-experts-slider/' &&
    windowPathname !== '/events/screver-btq' &&
    windowPathname !== '/events/screver-btq/' &&
    windowPathname !== '/events/screver-btq-program' &&
    windowPathname !== '/events/screver-btq-program/' &&
    windowPathname !== '/events/screver-btq-program/program' &&
    windowPathname !== '/events/screver-btq-program/program/' &&
    windowPathname !== '/events/screver-btq-program/cinema' &&
    windowPathname !== '/events/screver-btq-program/cinema/' &&
    windowPathname !== '/events/screver-btq-program/vote' &&
    windowPathname !== '/events/screver-btq-program/vote/' &&
    windowPathname !== '/events/screver-btq-program/feedback' &&
    windowPathname !== '/events/screver-btq-program/feedback/' &&
    windowPathname !== '/events/screver-btq-program/vote/keynote' &&
    windowPathname !== '/events/screver-btq-program/vote/keynote/' &&
    windowPathname !== '/events/screver-btq-program/vote/vr' &&
    windowPathname !== '/events/screver-btq-program/vote/vr/' &&
    windowPathname !== '/events/screver-btq-program/vote/gamification' &&
    windowPathname !== '/events/screver-btq-program/vote/gamification/' &&
    windowPathname !== '/events/screver-btq-program/vote/omnichannel' &&
    windowPathname !== '/events/screver-btq-program/vote/omnichannel/' &&
    windowPathname !== '/cases/cx/digitec' &&
    windowPathname !== '/cases/cx/digitec/' &&
    windowPathname !== '/cases/cx/digitec/cart' &&
    windowPathname !== '/cases/cx/digitec/cart/' &&
    windowPathname !== '/cases/cx/success' &&
    windowPathname !== '/cases/cx/success/' &&
    windowPathname !== '/cases/cx/galaxus' &&
    windowPathname !== '/cases/cx/galaxus/' &&
    windowPathname !== '/cases/cx/galaxus/survey-1' &&
    windowPathname !== '/cases/cx/galaxus/survey-1/' &&
    windowPathname !== '/cases/cx/galaxus/survey-2' &&
    windowPathname !== '/cases/cx/galaxus/survey-2/' &&
    windowPathname !== '/cases/cx/galaxus/survey-3' &&
    windowPathname !== '/cases/cx/galaxus/survey-3/' &&
    windowPathname !== '/cases/cx/galaxus/survey-4' &&
    windowPathname !== '/cases/cx/galaxus/survey-4/' &&
    windowPathname !== '/cases/cx/galaxus/survey-5' &&
    windowPathname !== '/cases/cx/galaxus/survey-5/' &&
    windowPathname !== '/cases/cx/ewz' &&
    windowPathname !== '/cases/cx/ewz/'
  ) {
    setTimeout(() => {
      const script = document.createElement('script');

      script.src =
        'https://go.screver.com/scripts/embed-survey?s=6202880753fdbf3478ce47bc&t=popover&c=capptoo&btnL=Pass the survey&i=question&sp=right&w=100%&h=800px&hH=true&hF=true';

      script.async = true;
      document.body.appendChild(script);
    }, 6000);
  }

  if (
    typeof window !== 'undefined' &&
    windowPathname.includes('/cases/cx/digitec')
  ) {
    setTimeout(() => {
      const scriptDigitec1 = document.createElement('script');

      scriptDigitec1.src =
        'https://go.screver.com/scripts/embed-survey?s=digitec-feedback&t=sidetab&c=screver-playground&btnL=Feedback&i=question&sp=right&w=100%&h=800px&hH=true&hF=true';

      scriptDigitec1.async = true;
      document.body.appendChild(scriptDigitec1);
      const scriptDigitec2 = document.createElement('script');

      scriptDigitec2.src =
        'https://go.screver.com/scripts/embed-survey?s=digitec-prize&t=popover&c=screver-playground&btnL=Pass the survey&i=fire&sp=right&w=100%&h=600px&hH=true&hF=true';

      scriptDigitec2.async = true;
      document.body.appendChild(scriptDigitec2);
    }, 2000);
  }

  if (
    typeof window !== 'undefined' &&
    windowPathname.includes('cases/cx/ewz')
  ) {
    setTimeout(() => {
      const scriptEwz1 = document.createElement('script');

      scriptEwz1.src =
        'https://go.screver.com/scripts/embed-survey?s=651150b30122b2510c2a6416&t=sidetab&c=ewz&btnL=Feedback&i=annotation&sp=right&w=100%&h=800px&hH=true&hF=true';

      scriptEwz1.async = true;
      document.body.appendChild(scriptEwz1);

      const scriptDigitec2 = document.createElement('script');

      scriptDigitec2.src =
        'https://go.screver.com/scripts/embed-survey?s=65112e2db6147c50da35cd2c&t=popover&c=ewz&btnL=Pass the survey&i=question&sp=right&w=100%&h=800px&hH=true&hF=true';

      scriptDigitec2.async = true;
      document.body.appendChild(scriptDigitec2);
    }, 2000);
  }

  if (typeof window !== 'undefined') {
    setTimeout(() => {
      const scriptGa = document.createElement('script');

      scriptGa.innerHTML = `
          const addTracker = () => {
            const tracker = window?.ga?.getAll()[0];
            return tracker?.get('clientId');
          };
          addTracker();
        `;

      scriptGa.async = true;
      document.body.appendChild(scriptGa);
    }, 1000);
  }

  // LinkedIn track script
  // if (windowPathname.includes('/webinar/')) {
  //   (function (l) {
  //     if (!l) {
  //       window.lintrk = function (a, b) {
  //         window.lintrk.q.push([a, b]);
  //       };

  //       window.lintrk.q = [];
  //     }

  //     const b = document.createElement('script');
  //     const s = document.getElementsByTagName('script')[0];
  //     b.type = 'text/javascript';
  //     b.async = true;
  //     b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
  //     s.parentNode.insertBefore(b, s);
  //   })(window.lintrk);

  //   setTimeout(() => {
  //     const linkdn = document.createElement('script');

  //     linkdn.innerHTML = `
  //       const addLITracker = () => {
  //         const liTracker = window?.lintrk('track', { conversion_id: 14697825 });
  //         return liTracker;
  //       };
  //       addLITracker();
  //     `;

  //     linkdn.async = true;
  //     document.body.appendChild(linkdn);
  //   }, 1000);
  // }

  // setTimeout(() => {
  //   /*Permacode*/
  //   (function (n) {
  //     var t = n.createElement('script'),
  //       i;
  //     t.type = 'text/javascript';
  //     t.async = !0;
  //     t.src = '//api.permaleads.ch/api.js';
  //     i = n.getElementsByTagName('script')[0];
  //     i.parentNode.insertBefore(t, i);
  //   })(document);

  //   var plapi = plapi || [];
  //   plapi.push(['Key', 'PL-1005-6ODEB']);
  //   plapi.push(['SendView', '']);

  //   (function () {
  //     var noscript = document.createElement('noscript');

  //     var iframe = document.createElement('iframe');

  //     iframe.src =
  //       '//api.permaleads.ch/noscript.php?type=view&k=PL-1005-6ODEB&r=[DYNAMICREFERRER]';

  //     iframe.height = '0';
  //     iframe.width = '0';
  //     iframe.style = 'display:none;visibility:hidden';

  //     noscript.appendChild(iframe);

  //     document.getElementsByTagName('head')[0].appendChild(noscript);
  //   })();

  //   /*Mouseflow*/
  //   window._mfq = window._mfq || [];

  //   (function () {
  //     var mf = document.createElement('script');
  //     mf.type = 'text/javascript';
  //     mf.defer = true;

  //     mf.src =
  //       '//cdn.mouseflow.com/projects/f4b57594-cf1d-40ae-a4a6-17739531391d.js';

  //     document.getElementsByTagName('head')[0].appendChild(mf);
  //   })();
  // }, 0);
};

exports.wrapRootElement = ({ element, props }) => {
  return <ContextProvider {...props}> {element} </ContextProvider>;
};
